import ace from 'ace-builds/src-noconflict/ace';
import beautify from 'ace-builds/src-noconflict/ext-beautify';
import { filesLocation } from '../../../../../config/env.json';

let next_entity_index = 0;
let timeout = null;
let video = window.document.createElement('video');

function log(obj) {
    return obj;
}

class Player {
    recorded_data = [];

    editor = null;
    recorder = null;
    video = video;

    constructor(_editor, _defaultContent = '') {
        this.editor = _editor;
        window.editor = this.editor;
        this.defaultContent = _defaultContent;
        this.video.src = filesLocation+window.location.pathname+"/video.mp4";
        this.init().then(() => {
            window.video = this.video;
            this.video.onended = () => {
                clearTimeout(timeout);
                this._editorMode();
            }
            this.video.style.position = 'fixed';
            this.video.style.top = '50px';
            this.video.style.left = '0px';
            this.video.style.height = "calc(100vh - 90px)";
            this.video.style.zIndex = 998;
            this.video.style.width = "100%";
            this.video.style.backgroundColor = "black";
            this.video.style.display = "none";
            this.video.controls = false;
            document.body.appendChild(this.video);
        })

    }

    async init() {
        try {
            const response = await fetch(filesLocation+window.location.pathname+"/data.json");
            this.recorded_data = await response.json();
            return true;
        } catch ($e) { return true; }
    }

    _playMode = () => {
        this.editor.setReadOnly(true);
        document.getElementById('ace-editor').style.pointerEvents = 'none';
        this.editor.setBehavioursEnabled(false);
        this.editor.setOptions({
            enableBasicAutocompletion: false,
            enableSnippets: false,
            enableLiveAutocompletion: false,
            enableAutoIndent: false
        });
    }

    _editorMode = () => {
        this.editor.setReadOnly(false);
        document.getElementById('ace-editor').style.pointerEvents = 'auto';
        this.editor.focus();
        this.editor.setBehavioursEnabled(true);
        this.editor.setOptions({
            enableBasicAutocompletion: true,
            enableSnippets: true,
            enableLiveAutocompletion: true,
            enableAutoIndent: true
        });
    }

    start = () => {
        if(!this.video.duration) { return; }
        // window.document.documentElement.requestFullscreen();
        this.editor.setValue(this.defaultContent);
        this._playMode();
        this.video.currentTime = 0;
        this.video.play();
        this.write(0);
    }

    play = () => {
        if(this.video.currentTime === 0) { this.start(); return; }
        if(this.video.currentTime === this.video.duration) { this.start(); return; }
        this._playMode();

        // get last recorded entity by videos current time
        const pastContent = this.recorded_data.filter(recorded_entity => recorded_entity[1] < (this.video.currentTime*1000));
        this.video.style.display = "none";
        this.overwrite(pastContent);

        // continue playing
        if(pastContent.length && pastContent[pastContent.length-1][0] == ">") {
            next_entity_index++;
        }
        this.write(next_entity_index, this.video.currentTime*1000);
        this.video.play();
        this.editor.setBehavioursEnabled(false);
    }

    pause = () => {
        this._editorMode();
        this.video.pause();
        clearTimeout(timeout);
        this.editor.setBehavioursEnabled(true);
    }

    seek = (e, _time) => {
        this._playMode();
        const paused = this.video.paused;
        const progress_bar = document.getElementById('progress_bar');
        this.video.pause();
        clearTimeout(timeout);
        const time = e ? (e.layerX*this.video.duration/progress_bar.clientWidth) : _time;
        this.video.currentTime = time;
        var pastContent = this.recorded_data.filter(recorded_entity => recorded_entity[1] < (time*1000));
        next_entity_index = pastContent.length;
        // this.video.currentTime = pastContent.length ? pastContent[pastContent.length-1][1]/1000 : 0;
        this.video.style.display = "none";
        this.overwrite(pastContent);

        if(!paused) {
            if(e) {
                this.video.play();
            }
            this.write(next_entity_index, this.video.currentTime*1000);
            // this.write(next_entity_index);
        } else {
            this._editorMode();
        }
    }

    startSolving = () => {
        if(this.recorded_data[next_entity_index] && this.recorded_data[next_entity_index][0] == ">") {
            // this.play();
        } else if (this.video.paused) {
            clearTimeout(timeout);
            var from = this.recorded_data.findIndex(recorded_entity => recorded_entity[0] == '>');
            this.video.currentTime = this.recorded_data[from][1]/1000;
            var pastContent = this.recorded_data.filter(recorded_entity => recorded_entity[1] < this.recorded_data[from][1]);
            this.overwrite(pastContent);
            this.video.play();
            this.write(from);
        } else {
            clearTimeout(timeout);
            var from = this.recorded_data.findIndex(recorded_entity => recorded_entity[0] == '>');
            this.video.currentTime = this.recorded_data[from][1]/1000;
            var pastContent = this.recorded_data.filter(recorded_entity => recorded_entity[1] < this.recorded_data[from][1]);
            this.overwrite(pastContent);
            this.write(from);
        }
    }

    write = (i, currentTime = this.video.currentTime*1000) => {
        const recorded_entity = this.recorded_data[i];
        if(!recorded_entity) { return; }
        const next_time = i === 0 ? (recorded_entity[1] - currentTime) : recorded_entity[1] - (currentTime || this.recorded_data[i-1][1]);
        // if(next_time < 0) { return; }
        timeout = setTimeout(() => {
            this.hireEntity(recorded_entity);
            if(recorded_entity[0] == "||") { return; }
            this.write(next_entity_index = i+1);
        }, next_time)
    }

    overwrite = (content) => {
        this.editor.setValue(this.defaultContent);
        this._playMode();
        for (let i = 0; i < content.length; i++) {
            const recorded_entity = content[i];
            this.hireEntity(recorded_entity, true);
        }
    }

    hireEntity = (entity, isOverWriting) => {
        if(this.video.currentTime*1000 < entity[1] && !isOverWriting && !this.video.paused) {
            setTimeout(() => {
                this.hireEntity(entity)
            }, entity[1]- this.video.currentTime*1000);
            return;
        }
        if(!isOverWriting) { console.log(entity); }
        const { editor } = this;
        if(entity[2]) {
            editor.gotoLine(entity[2][0], entity[2][1], true)
            editor.moveCursorTo(entity[2][0], entity[2][1])
            if(entity[2][0]+2 > editor.getLastVisibleRow()) {
                editor.scrollToLine(entity[2][0], true)
            }
            if(entity[2][0] < editor.getFirstVisibleRow()+2) {
                editor.scrollToLine(entity[2][0], true)
            }
        }
        if(entity[0] === 'r') {
            var ss = Date.now();
            editor.moveCursorTo(entity[2][0], entity[2][1])
            editor.selection.setSelectionRange(new ace.Range(
                entity[2][0],
                entity[2][1],
                entity[3][0],
                entity[3][1]
            ));
            editor.insert('');
        } else if(entity[0] === 'i') {
            var ss = Date.now();
            for (let j = 0; j < entity[4].length; j++) {
                const line = entity[4][j];
                editor.insert(line);
                if(j+1 < entity[4].length) {

                    var sp = editor.getCursorPosition();
                    editor.navigateLineEnd();
                    var ep = editor.getCursorPosition();
                    editor.selection.setSelectionRange(new ace.Range(sp.row, sp.column, ep.row, ep.column));
                    var text = editor.getSelectedText();
                    editor.splitLine();
                    editor.navigateDown(1);
                    editor.navigateLineStart();
                    editor.insert(text);
                    editor.navigateLineStart();

                    // // editor.setBehavioursEnabled(true);
                    // // editor.setOption('enableAutoIndent', true);
                    // editor.splitLine();
                    // // editor.setOption('enableAutoIndent', false);
                    // // editor.setBehavioursEnabled(false);
                    // editor.navigateDown(1);
                    // editor.navigateLineStart();
                    // // editor.moveCursorTo(entity[3][0], entity[3][1])
                }
            }
        } else if(entity[0] === 's') {
            editor.moveCursorTo(entity[2][0], entity[2][1])
            editor.selection.setSelectionRange(new ace.Range(
                entity[2][0],
                entity[2][1],
                entity[3][0],
                entity[3][1]
            ));
        } else if(entity[0] === 'b') {
            // pause();
            // timeout = setTimeout(() => {
                beautify.beautify(editor.session);
            // }, 1000);
        } else if(entity[0] === '||' && !isOverWriting) {
            clearTimeout(timeout);
            this.pause();
            if(document.getElementById('instruction-button').className.indexOf('btn-cta') == -1) {
                document.getElementById('instruction-button').click();
            }
            document.getElementById('task-button').click();
            // this._editorMode();
        // } else if (entity[0] === '>' && !isOverWriting) {
        //     clearTimeout(timeout);
        //     this.pause();
        } else if(entity[0] === 'hv') {
            this.video.style.display = "none";
        } else if(entity[0] === 'sv') {
            this.video.style.display = "block";
        }
    }

    async record() {
        this.addEditorEventListeners();
        const user_stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const desktop_stream = await navigator.mediaDevices.getDisplayMedia();
        this.user_recorder = new MediaRecorder(user_stream);
        this.desktop_recorder = new MediaRecorder(new MediaStream([...user_stream.getAudioTracks(), ...desktop_stream.getVideoTracks()]));
        let collected_user_stream = [];
        let collected_desktop_stream = [];
        this.user_recorder.ondataavailable = (ev) => { 
            if(!this.recording_paused) {
                collected_user_stream.push(ev.data); 
            }
        }
        this.desktop_recorder.ondataavailable = (ev) => { 
            if(!this.recording_paused) {
                collected_desktop_stream.push(ev.data); 
            }
        }
        this.user_recorder.onstop = (ev) => {
            this.collected_user_data = new Blob(collected_user_stream, { 'type': 'video/mp4;' }); 
            collected_user_stream = [];
            this.recording = false;
            user_stream.getTracks()[0].stop();
            // user_stream.getTracks()[1].stop();
        }
        this.desktop_recorder.onstop = (ev) => {
            this.collected_desktop_data = new Blob(collected_desktop_stream, { 'type': 'video/mp4;' }); 
            collected_desktop_stream = [];
            this.recording = false;
            desktop_stream.getTracks()[0].stop();
            // desktop_stream.getTracks()[1].stop();
        }
        window.onblur = () => {
            if(!this.recording || this.recording_paused) { return; }
            // desktop_stream.getVideoTracks()[0].enabled = true;
            // user_stream.getVideoTracks()[0].enabled = false;
            const timestamp = (Date.now() - this.started_recording_at);
            this.recorded_data.push(log([
                'sv', timestamp
            ]));
        }
        window.onfocus = () => {
            if(!this.recording || this.recording_paused) { return; }
            // desktop_stream.getVideoTracks()[0].enabled = false;
            // user_stream.getVideoTracks()[0].enabled = true;
            const timestamp = (Date.now() - this.started_recording_at);
            this.recorded_data.push(log([
                'hv', timestamp
            ]));
        }
        setTimeout(() => {
            this.recorded_data = [];
            // desktop_stream.getVideoTracks()[0].enabled = false;
            this.user_recorder.start();
            this.desktop_recorder.start();
            this.started_recording_at = Date.now();
            this.recording = true;
            this.editor.navigateFileEnd();
            const pos = this.editor.getCursorPosition();
            this.recorded_data.push(log([
                'i',
                (Date.now() - this.started_recording_at),
                [0,0],
                [pos.row, pos.column],
                this.editor.getValue().split('\n')
            ]))
        }, 3000);
    }

    stop() {
        const timestamp = (Date.now() - this.started_recording_at);
        this.recorded_data.push(log([
            '..', timestamp
        ]));
        this.user_recorder.stop();
        this.desktop_recorder.stop();
    }

    pauseRecording() {
        const timestamp = (Date.now() - this.started_recording_at);
        this.recorded_data.push(log([
            '||', timestamp
        ]));
        this.user_recorder.pause();
        this.desktop_recorder.pause();
        this.recording_paused = true;
    }

    resumeRecording() {
        const paused_time = (Date.now() - this.started_recording_at) - this.recorded_data[this.recorded_data.length-1][1];
        this.started_recording_at += paused_time;
        const timestamp = (Date.now() - this.started_recording_at);
        this.recorded_data.push(log([
            '>', timestamp
        ]));
        this.user_recorder.resume();
        this.desktop_recorder.resume();
        this.recording_paused = false;
    }

    addEditorEventListeners() {
        this.editor.on('change', (event) => {
            if(this.beautifying) { this.beautifying--; return; }
            if(!this.recording) { return; }
            if(this.recording_paused) { return; }
            if(event.action == 'insert') {
                var timestamp = (Date.now() - this.started_recording_at);
                // start += timestamp;
                this.recorded_data.push(log([
                    'i',
                    timestamp,
                    [event.start.row, event.start.column],
                    [event.end.row, event.end.column],
                    event.lines
                ]))
            }
            if(event.action == 'remove') {
                var timestamp = (Date.now() - this.started_recording_at);
                // start += timestamp;
                this.recorded_data.push(log([
                    'r',
                    timestamp,
                    [event.start.row, event.start.column],
                    [event.end.row, event.end.column],
                    event.lines
                ]))
            }
        });
        this.editor.selection.on("changeSelection", (event, selection) => {
            if(!this.recording) { return; }
            if(this.recording_paused) { return; }
            const range = (selection.getRange());
            var timestamp = (Date.now() - this.started_recording_at);
            // start += timestamp;
            this.recorded_data.push(log([
                's',
                timestamp,
                [range.start.row, range.start.column],
                [range.end.row, range.end.column],
            ]))
        });
        this.editor.commands.addCommand({
            name: 'pause-recording',
            bindKey: { win: 'Alt-u', mac: 'Command-u' },
            exec: () => {
                const timestamp = (Date.now() - this.started_recording_at);
                this.recorded_data.push(log([
                    '||', timestamp
                ]));
                this.user_recorder.pause();
                this.desktop_recorder.pause();
                this.recording_paused = true;
            }
        });
        this.editor.commands.addCommand({
            name: 'play-recording',
            bindKey: { win: 'Alt-i', mac: 'Command-i' },
            exec: () => {
                const paused_time = (Date.now() - this.started_recording_at) - this.recorded_data[this.recorded_data.length-1][1];
                this.started_recording_at += paused_time;
                const timestamp = (Date.now() - this.started_recording_at);
                this.recorded_data.push(log([
                    '>', timestamp
                ]));
                this.user_recorder.resume();
                this.desktop_recorder.resume();
                this.recording_paused = false;
            }
        });
        this.editor.on("paste", (event, editor) => {
            if(!this.recording) { return; }
            if(this.recording_paused) { return; }
            const timestamp = (Date.now() - this.started_recording_at) + 10;
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                this.recorded_data.push(log([
                    'b', timestamp, [editor.getCursorPosition().row, editor.getCursorPosition().column]
                ]));
                this.beautifying = 2;
                beautify.beautify(editor.session);
            }, 10);
        }); 
    }
}

export default Player