import { first } from 'lodash-es';
import React, { useState, ReactElement } from 'react';
import { ReflexContainer, ReflexSplitter, ReflexElement } from 'react-reflex';
import Helmet from 'react-helmet';
import { sortChallengeFiles } from '../../../../../utils/sort-challengefiles';
import { challengeTypes } from '../../../../utils/challenge-types';
import {
  ChallengeFile,
  ChallengeFiles,
  ResizeProps
} from '../../../redux/prop-types';
import ActionRow from './action-row';
import ControlPanel from '../components/control-panel';
import { Link } from 'gatsby';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { openModal } from '../redux';

import './layout.css';
import { Fragment } from 'react';

type Pane = { flex: number };

interface DesktopLayoutProps {
  block: string;
  challengeFiles: ChallengeFiles;
  challengeType: number;
  editor: ReactElement | null;
  hasEditableBoundaries: boolean;
  hasNotes: boolean;
  hasPreview: boolean;
  instructions: ReactElement;
  layoutState: {
    codePane: Pane;
    editorPane: Pane;
    instructionPane: Pane;
    notesPane: Pane;
    previewPane: Pane;
    testsPane: Pane;
  };
  notes: ReactElement;
  preview: ReactElement;
  resizeProps: ResizeProps;
  superBlock: string;
  testOutput: ReactElement;
  openCourseModal: any;
}

const reflexProps = {
  propagateDimensions: true
};

const DesktopLayout = (props: DesktopLayoutProps): JSX.Element => {
  const [showNotes, setShowNotes] = useState(false);
  const [showPreview, setShowPreview] = useState(true);
  const [showConsole, setShowConsole] = useState(false);
  const [showInstructions, setShowInstructions] = useState(true);

  const togglePane = (pane: string): void => {
    switch (pane) {
      case 'showPreview':
        setShowPreview(!showPreview);
        break;
      case 'showConsole':
        setShowConsole(!showConsole);
        break;
      case 'showNotes':
        setShowNotes(!showNotes);
        break;
      default:
        setShowConsole(false);
        setShowPreview(false);
        setShowNotes(false);
    }
  };

  const getChallengeFile = () => {
    const { challengeFiles } = props;
    return first(sortChallengeFiles(challengeFiles) as ChallengeFile[]);
  };

  const toggleInstruction = () => {
      setShowInstructions(!showInstructions)
  }

  const {
    block,
    challengeType,
    resizeProps,
    instructions,
    editor,
    testOutput,
    hasNotes,
    hasPreview,
    layoutState,
    notes,
    preview,
    hasEditableBoundaries,
    superBlock,
    openCourseModal
  } = props;

  const challengeFile = getChallengeFile();
  const projectBasedChallenge = hasEditableBoundaries;
  const isMultiFileCertProject =
    challengeType === challengeTypes.multiFileCertProject;
  const displayPreview =
    projectBasedChallenge || isMultiFileCertProject
      ? showPreview && hasPreview
      : hasPreview;
  const displayNotes = projectBasedChallenge ? showNotes && hasNotes : false;
  const displayConsole =
    projectBasedChallenge || isMultiFileCertProject ? showConsole : true;
  const {
    codePane,
    editorPane,
    instructionPane,
    notesPane,
    previewPane,
    testsPane
  } = layoutState;

  const backing = () => {
    // window.history.back();
    // location.href = "/learn/";
    const url = window.location.href;
    window.location.href = url.substring(0, url.lastIndexOf('/'));
  }

  return (
    <Fragment>
      <Helmet>
        <link rel="shortcut icon" href="/assets/images/logo/favourite_icon_1.png" />

        <link rel="stylesheet" type="text/css" href="/assets/css/bootstrap.min.css" />

        <link rel="stylesheet" type="text/css" href="/assets/css/icomoon.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/themify-icons.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/fontawesome-all.css" />

        <link rel="stylesheet" type="text/css" href="/assets/css/owl.carousel.min.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/owl.theme.default.min.css" />

        <link rel="stylesheet" type="text/css" href="/assets/css/aos.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/animate.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/splitting.css" />

        <link rel="stylesheet" type="text/css" href="/assets/css/magnific-popup.css" />

        <link rel="stylesheet" type="text/css" href="/assets/css/style.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/_extraise.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/extraise.css" />
        <style>
          {`:root{--header-height: 50px}
          #header_section {display: none !important;}
          .sticky_header, .sticky_header.stuck {
            min-height: 50px;
            box-shadow: none;
          }
          #universal-nav-logo, .navatar {display: none;}
          #header_section_challenge > div.container {max-width: 100%;}
          .btn.toggle-button-nav {display: none;}
          .universal-nav .challenge-only {display: flex;}`}
        </style>
      </Helmet>
      <header id="header_section_challenge" className="header_section sticky_header d-flex align-items-center text-white clearfix">
        <div className="container">
          <div className="row align-items-center">
            <nav className='universal-nav' id='universal-nav'>
              <div className='universal-nav-middle'>
                <div className="challenge-only">
                  <a onClick={ backing } className="back-button btn" style={{ marginRight: "5px", marginLeft: "0px", lineHeight: "11px", padding: "10px 25px", textAlign: "center" }}><i className="fas fa-chevron-left"></i></a>
                  <div className="course-menu" onClick={ openCourseModal }>
                    <a className="btn-menu">
                      <span></span>
                      <span></span>
                      <span></span>
                    </a>
                    <span>Course Menu</span>
                  </div>
                  <a id="instruction-button" className={showInstructions ? 'instruction-menu btn btn-cta' : 'instruction-menu btn'} onClick={ toggleInstruction }>
                    Instructions
                  </a>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>
      {(projectBasedChallenge || isMultiFileCertProject) && (
        <ActionRow
          block={block}
          hasNotes={hasNotes}
          showConsole={showConsole}
          showNotes={showNotes}
          showPreview={showPreview}
          superBlock={superBlock}
          togglePane={togglePane}
        />
      )}

      <ReflexContainer className="desktop-layout" orientation="horizontal">
        <ReflexElement flex={ 1 }>
          <ReflexContainer className='' orientation='vertical'>
            {!projectBasedChallenge && <ReflexElement
              flex={instructionPane.flex}
              className={showInstructions ? 'instructionPane show' : 'instructionPane hide'}
              {...resizeProps}
            >
              <div>
                {instructions}
              </div>
            </ReflexElement>}
            {!projectBasedChallenge && (
              <ReflexSplitter propagate={true} {...resizeProps} />
            )}
            <ReflexElement
              flex={editorPane.flex}
              className='editorPane'
              {...resizeProps}
            >
              {challengeFile && (
                <ReflexContainer key={challengeFile.fileKey} orientation='horizontal'>
                  <ReflexElement
                    flex={codePane.flex}
                    {...reflexProps}
                    {...resizeProps}
                  >
                    {<Fragment>{editor}</Fragment>}
                  </ReflexElement>
                  {displayConsole && (
                    <ReflexSplitter propagate={true} {...resizeProps} />
                  )}
                  {displayConsole && (
                    <ReflexElement
                      flex={testsPane.flex}
                      {...reflexProps}
                      {...resizeProps}
                    >
                      {testOutput}
                    </ReflexElement>
                  )}
                </ReflexContainer>
              )}
            </ReflexElement>
            {displayPreview && (
              <ReflexSplitter propagate={true} {...resizeProps} />
            )}
            {displayPreview && (
              <ReflexElement
                flex={previewPane.flex}
                {...resizeProps}
              >
                {preview}
              </ReflexElement>
            )}
          </ReflexContainer>
        </ReflexElement>
        <ReflexElement
          className='controlsPane'
        >
          <ControlPanel isMobile={true} />
        </ReflexElement>
      </ReflexContainer>
      {/* <ReflexContainer orientation='vertical'>
        {!projectBasedChallenge && (
          <ReflexElement flex={instructionPane.flex} {...resizeProps}>
            {instructions}
          </ReflexElement>
        )}
        {!projectBasedChallenge && (
          <ReflexSplitter propagate={true} {...resizeProps} />
        )}

        <ReflexElement flex={editorPane.flex} {...resizeProps}>
          {challengeFile && (
            <ReflexContainer
              key={challengeFile.fileKey}
              orientation='horizontal'
            >
              <ReflexElement
                flex={codePane.flex}
                {...reflexProps}
                {...resizeProps}
              >
                {editor}
              </ReflexElement>
              {displayConsole && (
                <ReflexSplitter propagate={true} {...resizeProps} />
              )}
              {displayConsole && (
                <ReflexElement
                  flex={testsPane.flex}
                  {...reflexProps}
                  {...resizeProps}
                >
                  {testOutput}
                </ReflexElement>
              )}
            </ReflexContainer>
          )}
        </ReflexElement>
        {displayNotes && <ReflexSplitter propagate={true} {...resizeProps} />}
        {displayNotes && (
          <ReflexElement flex={notesPane.flex} {...resizeProps}>
            {notes}
          </ReflexElement>
        )}

        {displayPreview && <ReflexSplitter propagate={true} {...resizeProps} />}
        {displayPreview && (
          <ReflexElement flex={previewPane.flex} {...resizeProps}>
            {preview}
          </ReflexElement>
        )}
        <ReflexElement className='controlsPane'>
          <ControlPanel isMobile={true} />
        </ReflexElement>
      </ReflexContainer> */}
    </Fragment>
  );
};

DesktopLayout.displayName = 'DesktopLayout';

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      openCourseModal: () => openModal('course')
    },
    dispatch
  );

// export default DesktopLayout;
export default connect(null, mapDispatchToProps)(DesktopLayout);
