/* eslint-disable @typescript-eslint/no-floating-promises */
import { graphql } from 'gatsby';
import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { TFunction, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { HandlerProps } from 'react-reflex';
import Media from 'react-responsive';
import { bindActionCreators, Dispatch } from 'redux';
import { createStructuredSelector } from 'reselect';
import store from 'store';
import { challengeTypes } from '../../../../utils/challenge-types';
import LearnLayout from '../../../components/layouts/learn';

import {
  ChallengeFile,
  ChallengeFiles,
  ChallengeMeta,
  ChallengeNode,
  ResizeProps,
  Test
} from '../../../redux/prop-types';
import { isContained } from '../../../utils/is-contained';
import ChallengeDescription from '../components/Challenge-Description';
import Hotkeys from '../components/Hotkeys';
import ResetModal from '../components/ResetModal';
import ChallengeTitle from '../components/challenge-title';
import CompletionModal from '../components/completion-modal';
import HelpModal from '../components/help-modal';
import Notes from '../components/notes';
import Output from '../components/ext_output';
import Preview from '../components/preview';
import ProjectPreviewModal, {
  PreviewConfig
} from '../components/project-preview-modal';
import SidePanel from '../components/side-panel';
import VideoModal from '../components/video-modal';
import CourseModal from '../components/CourseModal';
import {
  cancelTests,
  challengeFilesSelector,
  challengeMounted,
  challengeTestsSelector,
  consoleOutputSelector,
  createFiles,
  executeChallenge,
  initConsole,
  initTests,
  isChallengeCompletedSelector,
  previewMounted,
  updateChallengeMeta,
  openModal,
  setEditorFocusability
} from '../redux';
import { apiLocation } from '../../../../../config/env.json';
import { getGuideUrl } from '../utils';
import MultifileEditor from './MultifileEditor';
import DesktopLayout from './desktop-layout';
import MobileLayout from './mobile-layout';

import './classic.css';
import '../components/test-frame.css';
import { isSignedInSelector, userSelector } from '../../../redux';
import swal from 'sweetalert';

// Redux Setup
const mapStateToProps = createStructuredSelector({
  challengeFiles: challengeFilesSelector,
  isSignedIn: isSignedInSelector,
  user: userSelector,
  tests: challengeTestsSelector,
  output: consoleOutputSelector,
  isChallengeCompleted: isChallengeCompletedSelector
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      createFiles,
      initConsole,
      initTests,
      updateChallengeMeta,
      challengeMounted,
      executeChallenge,
      cancelTests,
      previewMounted,
      openModal,
      setEditorFocusability
    },
    dispatch
  );

// Types
interface ShowClassicProps {
  cancelTests: () => void;
  challengeMounted: (arg0: string) => void;
  createFiles: (arg0: ChallengeFile[]) => void;
  data: { challengeNode: ChallengeNode, allChallengeNode: any };
  executeChallenge: (options?: { showCompletionModal: boolean }) => void;
  challengeFiles: ChallengeFiles;
  isSignedIn: boolean;
  user: any;
  initConsole: (arg0: string) => void;
  initTests: (tests: Test[]) => void;
  isChallengeCompleted: boolean;
  output: string[];
  pageContext: {
    challengeMeta: ChallengeMeta;
    projectPreview: PreviewConfig & { showProjectPreview: boolean };
  };
  t: TFunction;
  tests: Test[];
  updateChallengeMeta: (arg0: ChallengeMeta) => void;
  openModal: (modal: string) => void;
  setEditorFocusability: (canFocus: boolean) => void;
  previewMounted: () => void;
}

interface ShowClassicState {
  layout: ReflexLayout;
  resizing: boolean;
}

interface ReflexLayout {
  codePane: { flex: number };
  editorPane: { flex: number };
  instructionPane: { flex: number };
  notesPane: { flex: number };
  previewPane: { flex: number };
  testsPane: { flex: number };
}

const MAX_MOBILE_WIDTH = 767;
const REFLEX_LAYOUT = 'challenge-layout';
const BASE_LAYOUT = {
  codePane: { flex: 1 },
  editorPane: { flex: 1 },
  instructionPane: { flex: 0.5 },
  previewPane: { flex: 0.7 },
  notesPane: { flex: 0.7 },
  testsPane: { flex: 0.3 }
};

// Component
class ShowClassic extends Component<ShowClassicProps, ShowClassicState> {
  static displayName: string;
  containerRef: React.RefObject<HTMLElement>;
  editorRef: React.RefObject<HTMLElement>;
  instructionsPanelRef: React.RefObject<HTMLDivElement>;
  resizeProps: ResizeProps;
  courses: any;
  swal_open: any;

  constructor(props: ShowClassicProps) {
    super(props);

    this.resizeProps = {
      onStopResize: this.onStopResize.bind(this),
      onResize: this.onResize.bind(this)
    };

    // layout: Holds the information of the panes sizes for desktop view
    this.state = {
      layout: this.getLayoutState(),
      resizing: false
    };

    this.containerRef = React.createRef();
    this.editorRef = React.createRef();
    this.instructionsPanelRef = React.createRef();
  }

  getLayoutState(): ReflexLayout {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const reflexLayout: ReflexLayout = store.get(REFLEX_LAYOUT);

    // Validate if user has not done any resize of the panes
    if (!reflexLayout) return BASE_LAYOUT;

    // Check that the layout values stored are valid (exist in base layout). If
    // not valid, it will fallback to the base layout values and be set on next
    // user resize.
    const isValidLayout = isContained(
      Object.keys(BASE_LAYOUT),
      Object.keys(reflexLayout)
    );

    return isValidLayout ? reflexLayout : BASE_LAYOUT;
  }

  onResize() {
    this.setState(state => ({ ...state, resizing: true }));
  }

  onStopResize(event: HandlerProps) {
    // @ts-expect-error TODO: Apparently, name does not exist on type
    const { name, flex } = event.component.props;

    // Only interested in tracking layout updates for ReflexElement's
    if (!name) {
      this.setState(state => ({ ...state, resizing: false }));
      return;
    }

    // Forcing a state update with the value of each panel since on stop resize
    // is executed per each panel.
    const newLayout =
      typeof this.state.layout === 'object'
        ? {
            ...this.state.layout,
            [name]: { flex }
          }
        : this.state.layout;

    this.setState({
      layout: newLayout,
      resizing: false
    });

    store.set(REFLEX_LAYOUT, this.state.layout);
  }

  componentDidMount() {
    const {
      data: {
        challengeNode: {
          challenge: { title }
        }
      }
    } = this.props;
    this.initializeComponent(title);
  }

  componentDidUpdate(prevProps: ShowClassicProps) {
    const {
      data: {
        challengeNode: {
          challenge: {
            title: prevTitle,
            fields: { tests: prevTests }
          }
        }
      }
    } = prevProps;
    const {
      data: {
        challengeNode: {
          challenge: {
            title: currentTitle,
            fields: { tests: currTests }
          }
        }
      }
    } = this.props;
    if (prevTitle !== currentTitle || prevTests !== currTests) {
      this.initializeComponent(currentTitle);
    }
  }

  initializeComponent(title: string) {
    const {
      challengeMounted,
      createFiles,
      initConsole,
      initTests,
      updateChallengeMeta,
      openModal,
      data: {
        challengeNode: {
          challenge: {
            challengeFiles,
            fields: { tests },
            challengeType,
            removeComments,
            helpCategory
          }
        }
      },
      pageContext: {
        challengeMeta,
        projectPreview: { showProjectPreview }
      }
    } = this.props;
    initConsole('');
    createFiles(challengeFiles ?? []);
    initTests(tests);
    if (showProjectPreview) openModal('projectPreview');
    updateChallengeMeta({
      ...challengeMeta,
      title,
      removeComments: removeComments !== false,
      challengeType,
      helpCategory
    });
    challengeMounted(challengeMeta.id);
  }

  componentWillUnmount() {
    const { createFiles, cancelTests } = this.props;
    createFiles([]);
    cancelTests();
  }

  getChallenge = () => this.props.data.challengeNode.challenge;

  getBlockNameTitle(t: TFunction) {
    const { block, superBlock, title } = this.getChallenge();
    return `${t(`intro:${superBlock}.blocks.${block}.title`)}: ${title}`;
  }

  getVideoUrl = () => this.getChallenge().videoUrl;

  getCourses() {
    if(this.courses) { return this.courses; }
    this.courses = [];
    this.props.data.allChallengeNode.edges.forEach((edge: any) => {
      var superBlock = this.courses.find((superBlock: any) => superBlock.title == edge.node.challenge.superBlock) || (this.courses[this.courses.length] = { title: edge.node.challenge.superBlock, blocks: [] });
      var block = superBlock.blocks.find((block: any) => block.title == edge.node.challenge.fields.blockName) || (superBlock.blocks[superBlock.blocks.length] = { title: edge.node.challenge.fields.blockName, chapters: [] });
      block.chapters.push({ title: edge.node.challenge.title, slug: edge.node.challenge.fields.slug });
    });
    return this.courses;
  }

  hasPreview() {
    const { challengeType } = this.getChallenge();
    return (
      challengeType === challengeTypes.html ||
      challengeType === challengeTypes.modern ||
      challengeType === challengeTypes.multiFileCertProject
    );
  }

  renderInstructionsPanel({ showToolPanel }: { showToolPanel: boolean }) {
    const {
      block,
      challengeType,
      description,
      forumTopicId,
      instructions,
      superBlock,
      title,
      translationPending
    } = this.getChallenge();

    const showBreadCrumbs =
      challengeType !== challengeTypes.multiFileCertProject;
    return (
      <SidePanel
        block={block}
        challengeDescription={
          <ChallengeDescription
            block={block}
            description={description}
            instructions={''}
          />
        }
        challengeTasks={
          <ChallengeDescription
            block={block}
            description={''}
            instructions={instructions}
          />
        }
        challengeTitle={
          <ChallengeTitle
            block={block}
            isCompleted={this.props.isChallengeCompleted}
            showBreadCrumbs={showBreadCrumbs}
            superBlock={superBlock}
            translationPending={translationPending}
          >
            {title}
          </ChallengeTitle>
        }
        guideUrl={getGuideUrl({ forumTopicId, title })}
        instructionsPanelRef={this.instructionsPanelRef}
        showToolPanel={showToolPanel}
        videoUrl={this.getVideoUrl()}
      />
    );
  }

  renderEditor() {
    const {
      pageContext: {
        projectPreview: { showProjectPreview }
      },
      challengeFiles,
      data: {
        challengeNode: {
          challenge: {
            fields: { tests },
            usesMultifileEditor
          }
        }
      }
    } = this.props;
    const { description, title } = this.getChallenge();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return (
      challengeFiles && (
        <MultifileEditor
          challengeFiles={challengeFiles}
          containerRef={this.containerRef}
          description={description}
          editorRef={this.editorRef}
          initialTests={tests}
          resizeProps={this.resizeProps}
          title={title}
          usesMultifileEditor={usesMultifileEditor}
          showProjectPreview={showProjectPreview}
        />
      )
    );
  }

  renderTestOutput() {
    const { output, t } = this.props;
    return (
      <Output
        defaultOutput={`/**
* Your Console and test output will go here
*/
`}
        output={output}
      />
    );
  }

  renderNotes(notes?: string) {
    return <Notes notes={notes} />;
  }

  renderPreview() {
    return (
      <Preview
        className='full-height'
        disableIframe={this.state.resizing}
        previewMounted={this.props.previewMounted}
      />
    );
  }

  render() {
    const {
      block,
      challengeType,
      fields: { blockName, slug },
      forumTopicId,
      hasEditableBoundaries,
      superBlock,
      certification,
      title,
      usesMultifileEditor,
      notes,
      id: challengeId
    } = this.getChallenge();
    const {
      executeChallenge,
      pageContext: {
        challengeMeta: { nextChallengePath, prevChallengePath },
        projectPreview
      },
      challengeFiles,
      isSignedIn,
      user,
      t
    } = this.props;

    let locked = false, pro = false;
    const courses: any = this.getCourses();
    const course: any = courses.find((c: any) => c.title == superBlock).blocks.find((b: any) => b.title == blockName);
    const chapterIndex: any = course.chapters.findIndex((chapter: any) => chapter.slug == slug);

    if(!isSignedIn && chapterIndex > 0) {
      locked = true;
    }
    // if(user && user.completedChallenges) {
    //   console.log(user.completedChallenges.length)
    //   console.log(user.completedChallenges.findIndex(challenge => challenge.id == challengeId));
    // }
    if(user.isDonating) {
      pro = false;
    } else if(chapterIndex > 0 && user && user.completedChallenges && user.completedChallenges.length >= 20 && user.completedChallenges.findIndex((challenge: any) => challenge.id == challengeId) == -1) {
      pro = true;
    }

    if(pro) {
      // setTimeout(() => {
        this.swal_open = true;
        swal({
          title: "Become Pro Programmer",
          icon: 'warning',
          closeOnClickOutside: false,
          closeOnEsc: false,
          text: 'Become a Pro Member for complete access to this course and all other courses.',
          buttons: {
            return: {
              text: 'Return To Syllabus',
              className: 'transparent',
              closeModal: false
            },
            trial: {
              text: "Start Free Trial",
              className: "dark",
              closeModal: false,
            },
          },
        })
        .then((value) => {
          if(value == 'trial') {
            window.location.href = '/payment/';
          } else if (value == 'return') {
            window.location.href = '/learn/';
          }
        });
      // }, 500);
      // return null;
    } else if(typeof window == 'object' && locked) {
      // setTimeout(() => {
        this.swal_open = true;
        swal({
          closeOnClickOutside: false,
          closeOnEsc: false,
          text: 'You must login to access this chapter',
          buttons: {
            return: {
              visible: !isSignedIn,
              text: "Back to Learn",
              closeModal: false,
            },
            signin: {
              text: "Login",
              closeModal: false,
            },
          },
        })
        .then((value) => {
          console.log(value);
          if(value == 'signin') {
            window.localStorage.afterSignIn = window.location.href;
            window.location.href = apiLocation + '/signin';
          } else if(value == 'return') {
            window.history.back();
            // window.location.href = '/learn/';
          }
        });
      // }, 500);
      // return null;
    } else {
      if(this.swal_open) {
        console.log('closing swal popup');
        window.swal.close();
        this.swal_open = false;
      }
    }

    return (
      <Hotkeys
        editorRef={this.editorRef}
        executeChallenge={executeChallenge}
        innerRef={this.containerRef}
        instructionsPanelRef={this.instructionsPanelRef}
        nextChallengePath={nextChallengePath}
        prevChallengePath={prevChallengePath}
        usesMultifileEditor={usesMultifileEditor}
      >
        <LearnLayout>
          <Helmet title={`${this.getBlockNameTitle(t)} | extraise.com.org`} />
          <Media maxWidth={MAX_MOBILE_WIDTH}>
            <MobileLayout
              editor={this.renderEditor()}
              guideUrl={getGuideUrl({ forumTopicId, title })}
              hasEditableBoundaries={hasEditableBoundaries}
              hasNotes={!!notes}
              hasPreview={this.hasPreview()}
              instructions={this.renderInstructionsPanel({
                showToolPanel: false
              })}
              notes={this.renderNotes(notes)}
              preview={this.renderPreview()}
              testOutput={this.renderTestOutput()}
              usesMultifileEditor={usesMultifileEditor}
              videoUrl={this.getVideoUrl()}
            />
          </Media>
          <Media minWidth={MAX_MOBILE_WIDTH + 1}>
            <DesktopLayout
              block={block}
              challengeFiles={challengeFiles}
              challengeType={challengeType}
              editor={this.renderEditor()}
              hasEditableBoundaries={hasEditableBoundaries}
              hasNotes={!!notes}
              hasPreview={this.hasPreview()}
              instructions={this.renderInstructionsPanel({
                showToolPanel: false
              })}
              layoutState={this.state.layout}
              notes={this.renderNotes(notes)}
              preview={this.renderPreview()}
              resizeProps={this.resizeProps}
              superBlock={superBlock}
              testOutput={this.renderTestOutput()}
            />
          </Media>
          <CompletionModal
            block={block}
            blockName={blockName}
            certification={certification}
            superBlock={superBlock}
          />
          <HelpModal />
          <VideoModal videoUrl={this.getVideoUrl()} />
          <ResetModal />
          <CourseModal course={ course } challenge={this.getChallenge()}/>
          <ProjectPreviewModal previewConfig={projectPreview} />
        </LearnLayout>
      </Hotkeys>
    );
  }
}

ShowClassic.displayName = 'ShowClassic';

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ShowClassic));

export const query = graphql`
  query ClassicChallenge($slug: String!) {
    allChallengeNode(sort: { fields: [challenge___superOrder, challenge___order, challenge___challengeOrder] }) {
      edges {
        node {
          challenge {
            fields {
              blockName
              slug
            }
            block
            title
            superBlock
            dashedName
          }
        }
      }
    }
    challengeNode(challenge: { fields: { slug: { eq: $slug } } }) {
      challenge {
        id
        block
        title
        description
        hasEditableBoundaries
        instructions
        notes
        removeComments
        challengeType
        helpCategory
        videoUrl
        superBlock
        certification
        translationPending
        forumTopicId
        fields {
          blockName
          slug
          tests {
            text
            testString
          }
        }
        required {
          link
          src
        }
        usesMultifileEditor
        challengeFiles {
          fileKey
          ext
          name
          contents
          head
          tail
          editableRegionBoundaries
          history
        }
      }
    }
  }
`;
