import React, { useEffect, ReactElement, useState } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { Test } from '../../../redux/prop-types';

import { mathJaxScriptLoader } from '../../../utils/script-loaders';
import { challengeTestsSelector } from '../redux';
import TestSuite from './test-suite';
import ToolPanel from './tool-panel';

import './side-panel.css';

declare var document: any;
declare var window: any;

const mapStateToProps = createSelector(
  challengeTestsSelector,
  (tests: Test[]) => ({
    tests
  })
);
interface SidePanelProps {
  block: string;
  challengeDescription: ReactElement;
  challengeTasks: ReactElement;
  challengeTitle: ReactElement;
  guideUrl: string;
  instructionsPanelRef: React.RefObject<HTMLDivElement>;
  showToolPanel: boolean;
  tests: Test[];
  videoUrl: string;
}

export function SidePanel({
  block,
  challengeDescription,
  challengeTasks,
  challengeTitle,
  guideUrl,
  instructionsPanelRef,
  showToolPanel = false,
  tests,
  videoUrl
}: SidePanelProps): JSX.Element {
  useEffect(() => {
    const MathJax = global.MathJax;
    const mathJaxMountPoint = document.querySelector('#mathjax');
    const mathJaxChallenge =
      block === 'rosetta-code' || block === 'project-euler';
    if (MathJax) {
      // Configure MathJax when it's loaded and
      // users navigate from another challenge
      MathJax.Hub.Config({
        tex2jax: {
          inlineMath: [
            ['$', '$'],
            ['\\(', '\\)']
          ],
          processEscapes: true,
          processClass: 'rosetta-code|project-euler'
        }
      });
      MathJax.Hub.Queue([
        'Typeset',
        MathJax.Hub,
        document.querySelector('.rosetta-code'),
        document.querySelector('.project-euler')
      ]);
    } else if (!mathJaxMountPoint && mathJaxChallenge) {
      mathJaxScriptLoader();
    }
  }, [block]);

  const [current_tab, setCurrentTab] = useState('learn');

  return (
    <div
      className='instructions-panel'
      ref={instructionsPanelRef}
      tabIndex={-1}
    >
      {challengeTitle}
      <section id="feature_section" className="feature_section bg_gray clearfix">
        <div className="container">
          <div className="feature_item">
            <div className="row align-items-top justify-content-lg-between justify-content-md-center justify-content-sm-center">
              <div className="col-lg-12 col-md-12 col-sm-9">
                <div className="section_title increase_size">
                  <div className="tabs_nav ul_li">
                    <ul className="nav" role="tablist">
                      <li>
                        <a className={ current_tab == 'learn' ? "active" : '' } href="#learn_tab" onClick={ () => setCurrentTab("learn") }>Learn</a>
                      </li>
                      <li>
                        <a className={ current_tab == 'tasks' ? "active" : '' } href="#test_tab" onClick={ () => setCurrentTab("tasks") } id="task-button">Tasks</a>
                      </li>
                    </ul>
                  </div>
                  <div className="tab-content mb-30">
                    <div id="learn_tab" className={ current_tab == 'learn' ? "tab-pane active" : 'tab-pane' }>
                      <button className="btn btn-primary tutorial" onClick={() => {
                        window.player.video.currentTime = 0;
                        document.getElementById('play-button')?.click();
                        document.getElementById('instruction-button')?.click();
                      }}><i className="fas fa-play"></i><span>&nbsp;&nbsp;&nbsp;&nbsp;Watch Tutorial</span></button>
                      {challengeDescription}
                    </div>
                    <div id="test_tab" className={ current_tab == 'tasks' ? "tab-pane active" : 'tab-pane' }>
                      {challengeTasks}
                      <button className="btn btn-primary tutorial" onClick={() => document.getElementById('test-button').click()}><i className="fas fa-code"></i><span>&nbsp;&nbsp;&nbsp;&nbsp;Test Your Code</span></button>
                      <TestSuite tests={tests} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section >
      {showToolPanel && <ToolPanel guideUrl={guideUrl} videoUrl={videoUrl} isMobile={true} />}
    </div >
  );
}

SidePanel.displayName = 'SidePanel';

export default connect(mapStateToProps)(SidePanel);
