import React, { useEffect } from 'react';
import PropTypes, { any } from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { Button, Modal } from '@freecodecamp/react-bootstrap';
import { Link } from 'gatsby';
import { isCourseModalOpenSelector, closeModal, resetChallenge } from '../redux';
import { executeGA } from '../../../redux';

import './course-modal.css';

declare var document: any;

const propTypes = {
  courses: any,
  challenge: any,
  close: PropTypes.func.isRequired,
  executeGA: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired
};

const mapStateToProps = createSelector(
  isCourseModalOpenSelector,
  isOpen => ({
    isOpen
  })
);

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      close: () => closeModal('course'),
      executeGA,
      reset: () => resetChallenge()
    },
    dispatch
  );

function withActions(...fns: any) {
  return () => fns.forEach((fn: any) => fn());
}

function CourseModal({ reset, close, isOpen, course, challenge }: any) {
  
  const _course = course;
  if (isOpen) {
    executeGA({ type: 'modal', data: '/course-modal' });
  }

  useEffect(() => {
    setTimeout(() => {
      if(document.getElementById('courses_list')) { document.getElementById('courses_list').style.opacity = '1' };
      document.getElementById('active_course')?.scrollIntoView();
    }, 700);
  });

  return (
    <Modal
      animation={false}
      dialogClassName='course-modal'
      keyboard={true}
      onHide={close}
      show={false || isOpen}
    >
      <Modal.Header className='course-modal-header' closeButton={false}>
        <Modal.Title className=''>
          {/* Learn { _course.superTitle } <br />  */}
          <span>{ _course.title }</span>
        </Modal.Title>
        <a className="btn-close" onClick={ close }>
          <i className="fal fa-times"></i>
        </a>
      </Modal.Header>
      <Modal.Body className='course-modal-body'>
        <span>Chapters</span>
        <ul id="courses_list" style={{opacity: 0}}>
          {

            _course.chapters.map((chapter: any, index: any) => {
              return <li key={index} className={ chapter.slug == challenge.fields.slug ? 'active' : '' } id={ chapter.slug == challenge.fields.slug ? 'active_course' : ''}>
                <Link onClick={ close } to={chapter.slug}>
                  { chapter.title }
                </Link>
              </li>
            })
          }
        </ul>
      </Modal.Body>
    </Modal>
  );
}

CourseModal.displayName = 'CourseModal';
CourseModal.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CourseModal);
