import React, { Component } from 'react';
import PropTypes from 'prop-types';
import sanitizeHtml from 'sanitize-html';

import { isEmpty } from 'lodash';

import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';

const propTypes = {
  defaultOutput: PropTypes.string,
  output: PropTypes.arrayOf(PropTypes.string)
};

function decodeEntities(encodedString: any) {
  var textArea = document.createElement('textarea');
  textArea.innerHTML = encodedString;
  return textArea.value;
}
class Output extends Component {
    static displayName: String;
    static propTypes: any;
  render() {
    const { output, defaultOutput } : any = this.props;
    const message = sanitizeHtml(
      !isEmpty(output) ? output.join('\n') : defaultOutput,
      {
        // allowedTags: ['b', 'i', 'em', 'strong', 'code', 'wbr', 'olecons-run']
        allowedTags: []
      }
    );
    return (
      <div style={{ overflowY: 'auto', height: 'calc(100% - 50px)', marginRight: '-15px' }} className='output-text'>
        <div style={{ overflowAnchor: 'none' }}>
          <SyntaxHighlighter language='json5' style={dracula}>
            {decodeEntities(message)}
          </SyntaxHighlighter>
        </div>
        <div style={{ overflowAnchor: 'auto', height: '1px' }} />
      </div>
    );
  }
}

Output.displayName = 'Output';
Output.propTypes = propTypes;

export default Output;
