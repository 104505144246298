import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { useTranslation, withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import sanitizeHtml from 'sanitize-html';
import {
  faCheckCircle,
  faInfoCircle,
  faRecordVinyl,
  faStop,
  faPause,
  faPlay,
  faTimesCircle,
  faBars,
  faPauseCircle,
  faPlayCircle
} from '@fortawesome/free-solid-svg-icons';
import { filesLocation } from '../../../../../config/env.json';

import {
  Button,
  DropdownButton,
  MenuItem
} from '@freecodecamp/react-bootstrap';

import './control-panel.css';
import { openModal, executeChallenge } from '../redux';
import { userSelector } from '../../../redux';

declare var document: any;
declare var window: any;

const mapStateToProps = createSelector(
  userSelector,
  (user) => ({ user })
);
const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      executeChallenge,
      openHelpModal: () => openModal('help'),
      openVideoModal: () => openModal('video'),
      openResetModal: () => openModal('reset')
    },
    dispatch
  );

const propTypes = {
  executeChallenge: PropTypes.func.isRequired,
  guideUrl: PropTypes.string,
  isMobile: PropTypes.bool,
  openHelpModal: PropTypes.func.isRequired,
  openResetModal: PropTypes.func.isRequired,
  openVideoModal: PropTypes.func.isRequired,
  videoUrl: PropTypes.string
};

class ToolPanel extends Component<any, any> {
  static displayName: string;
  static propTypes: any;

  isAdmin = false;
  constructor(props: any) {
    super(props);
    this.state = {
      playing: false
    }
    this.play = this.play.bind(this);
    this.pause = this.pause.bind(this);
    this.seek = this.seek.bind(this);
    this.record = this.record.bind(this);
    this.stop = this.stop.bind(this);
    this.upload = this.upload.bind(this);
    this.testInstructions = this.testInstructions.bind(this);
    this.pauseRecording = this.pauseRecording.bind(this);
    this.resumeRecording = this.resumeRecording.bind(this);
    if(['meoknu@gmail.com'].indexOf(this.props.user.email) >= 0) { this.isAdmin = true; }
  }
  componentWillUnmount() {
    const { player } : any = window;
    if(player && player.video && player.video.onplay) {
      player.video.onplay = null;
      player.video.onpause = null;
      player.video.ontimeupdate = null;
    }
  }
  componentDidMount() {
    const { player } : any = window;
  }
  play() {
    const { player } : any = window;
    player.play();
    if(!player.video.onplay) {
      player.video.onplay = () => {
        this.setState({
          playing: true
        })
      }
      player.video.onpause = () => {
        this.setState({
          playing: false
        })
      }
      player.video.ontimeupdate = () => {
        this.setState({
          width: player.video.currentTime*100/player.video.duration + '%'
        })
      }
    }
  }
  pause() {
    const { player } : any = window;
    player.pause();
  }
  seek(e: any) {
    const { player } : any = window;
    player.seek(e.nativeEvent);
  }
  record() {
    const { player } : any = window;
    player.record();
    this.setState({
      recording: true
    })
  }
  stop() {
    const { player } : any = window;
    player.stop();
    this.setState({
      recording: false
    })
  }
  upload() {
    const { player } : any = window;
    alert("uploading");

    const formData = new FormData();
    const userBlob = player.collected_user_data;
    const desktopBlob = player.collected_desktop_data;
    formData.append('user', userBlob, window.location.pathname.split("/").join("+"));
    formData.append('desktop', desktopBlob, window.location.pathname.split("/").join("+"));
    formData.append('data', JSON.stringify(player.recorded_data));

    var request = new XMLHttpRequest();
    // request.withCredentials = "true";
    request.onload = () => { alert('uploaded'); };
    request.open("POST", filesLocation+"/video");
    request.send(formData);
  }
  testInstructions(e: any) {
    const { executeChallenge } : any = this.props;
    document.getElementById('test-suite-section').style.display = 'flex';
    if(document.getElementById('instruction-button').className.indexOf('btn-cta') == -1) {
        document.getElementById('instruction-button').click();
    }
    document.getElementById('task-button').click();
    window.player.editor.focus();
    executeChallenge({ showCompletionModal: true });
  }
  pauseRecording() {
    this.setState({
      recording_paused: true
    });
    const { player } : any = window;
    player.pauseRecording();
  }
  resumeRecording() {
    this.setState({
      recording_paused: false
    });
    const { player } : any = window;
    player.resumeRecording();
  }
  render() {
    const {
      executeChallenge,
      isMobile,
      openHelpModal,
      openVideoModal,
      openResetModal,
      guideUrl,
      toggleInstruction,
      user,
      videoUrl, t } = this.props;
    const { width, playing, recording, recording_paused } = this.state;
    const { testInstructions, play, pause, seek, record, stop, upload, pauseRecording, resumeRecording } = this;
    // console.log(this.props);
    return (
      <Fragment>
        <div
          className={`tool-panel-group button-group ${
            isMobile ? 'tool-panel-group-mobile' : ''
          }`}
        >
          <div style={{ lineHeight: 0 }}><small>License: CC-BY-SA-4.0 by freeCodeCamp.org</small></div>
          { toggleInstruction && <a onClick={ toggleInstruction }>
            <FontAwesomeIcon
              color='#f3f3f3'
              icon={faInfoCircle}
              style={{ fontSize: '22px' }}
            />
          </a> }
          <div id='progress_bar' onMouseUp={ seek }>
            <div id='progressed' style={{ width }}/>
          </div>
          { !playing && <a onClick={ play } id="play-button">
            <FontAwesomeIcon
              color='#8990ef'
              icon={faPlay}
              style={{ fontSize: '16px' }}
            />
          </a> }
          { playing && <a onClick={ pause }>
            <FontAwesomeIcon
              color='#8990ef'
              icon={faPause}
              style={{ fontSize: '16px' }}
            />
          </a> }
          { this.isAdmin && !recording && <a onClick={ record }>
            <FontAwesomeIcon
              color='#8990ef'
              icon={faRecordVinyl}
              style={{ fontSize: '16px' }}
            />
          </a> }
          { this.isAdmin && recording && !recording_paused && <a onClick={ pauseRecording }>
            <FontAwesomeIcon
              color='#8990ef'
              icon={faPauseCircle}
              style={{ fontSize: '16px' }}
            />
          </a> }
          { this.isAdmin && recording && recording_paused && <a onClick={ resumeRecording }>
            <FontAwesomeIcon
              color='#8990ef'
              icon={faPlayCircle}
              style={{ fontSize: '16px' }}
            />
          </a> }
          { this.isAdmin && recording && <a onClick={ stop }>
            <FontAwesomeIcon
              color='#8990ef'
              icon={faStop}
              style={{ fontSize: '16px' }}
            />
          </a> }
          { this.isAdmin && <Button block={true} bsStyle='danger' onClick={upload}>
            {'Upload'}
          </Button> }
          <Button block={true} bsStyle='primary' onClick={ testInstructions } id="test-button">
            {isMobile ? t('buttons.run') : t('buttons.run-test')}
          </Button>
          <Button
            block={true}
            bsStyle='danger'
            className='btn-invert'
            onClick={openResetModal}
          >
            {isMobile ? t('buttons.reset') : t('buttons.reset-code')}
          </Button>
        </div>
      </Fragment>
    );
  }
}

ToolPanel.displayName = 'ToolPanel';
ToolPanel.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ToolPanel));
